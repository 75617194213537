<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'payments-methods-block-item-v3',
  components: {
    BaseIcon,
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div
    class="relative flex min-h-16 cursor-pointer items-center justify-start gap-4 rounded-lg border bg-white px-4 py-2.5 outline outline-2 outline-transparent transition-all duration-300 hover:outline-primary"
    :class="[
      {
        'pointer-events-none opacity-50': isDisabled,
      },
      small ? 'sm:min-h-16 sm:px-4' : 'sm:min-h-20 sm:px-6',
    ]"
    @click="$emit('click')"
  >
    <div class="flex grow items-center space-x-2">
      <div
        v-if="$slots.icon"
        class="flex h-10 w-10 items-center justify-center text-eonx-neutral-600"
        :class="small ? 'sm:h-10 sm:w-10' : 'sm:h-12 sm:w-12'"
      >
        <slot name="icon" />
      </div>
      <div
        v-if="$slots.default || $slots.subtitle"
        class="flex h-12 shrink grow basis-0 items-center justify-start gap-8"
      >
        <span class="text-sm font-bold text-eonx-neutral-800 sm:text-base">
          <slot v-if="$slots.default" />
        </span>
        <span v-if="$slots.subtitle" class="text-sm text-eonx-neutral-600">
          <slot name="subtitle" />
        </span>
      </div>
    </div>
    <div v-if="!isDisabled" class="flex shrink-0 items-center justify-center">
      <slot name="action">
        <base-icon svg="heroicons/solid/chevron-right" :size="24" />
      </slot>
    </div>
  </div>
</template>
